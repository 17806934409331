import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogComponent } from './blog.component';
import { SharedModule } from '../shared/shared.module';
import { BlogHeroComponent } from './components/blog-hero/blog-hero.component';
import { BlogNewsComponent } from './components/blog-news/blog-news.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {MatChipsModule} from '@angular/material/chips';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    BlogComponent,
    BlogHeroComponent,
    BlogNewsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatChipsModule,
    RouterModule
  ]
})
export class BlogModule { }
