<section class="companies-hero">
    <section class="title">
        <h1 class="text-box">
            Fortaleça a
            <span class="green">
                cultura de doação
            </span>
            da sua empresa!
        </h1>
        <p>Existem muitas maneiras de usar seu poder para impulsionar a generosidade e fazer a diferença em sua
            comunidade no dia
            <span class="date">{{date | date:'d \'de\' MMMM, y'}}</span>, #DiaDeDoar!
        </p>
        <p>E nós realmente acreditamos que juntos podemos fazer muita diferença.</p>
        <p>Todos os anos, no GivingTuesday e no Dia de Doar, empresas e marcas mostram seu compromisso em fazer o bem de
            maneiras
            criativas. Este kit de ferramentas contém tudo o que você precisa para participar do movimento global de
            generosidade.</p><a mat-button class="round background-light-yellow "
            href="https://relacionamento.captadores.org.br/ddd-lp-perene-empresas-download-de-materiais"
            target="_blank">Inscreva-se</a>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_empresas.png" alt="picture">
    </section>
</section>