<section class="discover">
    <section class="header">
        <h2>Descubra sua causa e doe!</h2>
        <p>A generosidade radical tem muitas formas de acontecer! Você pode doar para uma instituição, compartilhar sua
            história de
            doação conosco ou se voluntariar.</p>
    </section>
    <section class="cards">
        <mat-card>
            <mat-card-header>
                <img class="card-image" src="assets/banners/azul_doe_para_uma_ong.png" alt="">
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <h3>Doe para uma ONG</h3>
                    <span>
                        Veja as insittuições participantes do Dia de Doar e encontre sua causa
                    </span>
                    <a class="round stroke" mat-button [routerLink]="'/ongs'">Saiba mais</a>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <img class="card-image" src="assets/banners/azul_mapa_generosidade.png" alt="">
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <h3>Mapa da generosidade</h3>
                    <span>
                        Os participantes do Dia de Doar estão espalhados pelo Brasil todo. Econtre parceiros na sua
                        região!
                    </span>
                    <a class="round stroke" mat-button [routerLink]="'/inscreva-se'">Saiba mais</a>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <img class="card-image" src="assets/banners/azul_doe_seu_tempo.png" alt="">
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <h3>Doe seu tempo ou talento</h3>
                    <span>
                        Voluntarie-se em uma ação da Atados em parceria com o Dia de Doar
                    </span>
                    <a class="round stroke" mat-button
                        href="https://atados.com.br/vagas?categories=87&searchType=1&types=1" target="_blank">Saiba
                        mais</a>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <img class="card-image" src="assets/banners/azul_compartilhe_historia.png" alt="">
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <h3>Compartilhe sua história </h3>
                    <span>
                        Tem uma história inspiradora de generosidade? Adicione no Mapa da Generosidade!
                    </span>
                    <a class="round stroke p" mat-button [routerLink]="'/inscreva-se'">Saiba mais</a>
                </div>
            </mat-card-content>
        </mat-card>
    </section>
</section>