<app-header [pages]="pages" (toggleMenu)="sidenav.toggle()"></app-header>
<mat-sidenav-container class="app-container">
    <mat-sidenav #sidenav mode="over" [fixedInViewport]="false" [class.mat-elevation-z2]="true" class="app-sidenav"
        position="end">
        <app-sidenav-list [pages]="pages"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content class="content">
        <app-store-button></app-store-button>
        <router-outlet>
        </router-outlet>
        <app-footer [pages]="pages"></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>