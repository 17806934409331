<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Existimos para impulsionar a
            <span class="blue">generosidade radical</span>
            e fortalecer a
            <span class="green">
                cultura de doação
            </span>
            no Brasil
        </h1>
        <a href="https://www.youtube.com/watch?v=PaVAlS1G8RY" target="_blank" class="round background-dark-blue"
            mat-button>Assista
            como funciona</a>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_home.png" alt="picture">
    </section>
</section>