<section class="media">
    <h2>Principais espaços de mídia</h2>
    <p>
        <a href="https://valor.globo.com/brasil/noticia/2022/11/29/dia-do-doar-atrai-empresas-para-crescer.ghtml"
            target="_blank">Dia de
            Doar atrai empresas para crescer</a> (Valor Econômico, 2022)
    </p>
    <p>
        <a target="_blank"
            href="https://www1.folha.uol.com.br/colunas/papo-de-responsa/2022/11/compromisso-com-a-doacao-para-mudar-o-brasil.shtml">Compromisso
            com a doação para mudar o Brasil</a> (Folha de S. Paulo, 2022)
    </p>
    <p>
        <a target="_blank"
            href="https://www1.folha.uol.com.br/colunas/papo-de-responsa/2022/11/compromisso-com-a-doacao-para-mudar-o-brasil.shtml">Marketing
            Relacionado à Causa: como conectar empresas e organizações sociais</a> (Meio & Mensagem, 2022)
    </p>
    <p>
        <a target="_blank"
            href="https://www1.folha.uol.com.br/colunas/papo-de-responsa/2022/11/compromisso-com-a-doacao-para-mudar-o-brasil.shtml">Depois
            de consumir, hoje é dia de doar, em movimento global de filantropia</a> (Exame, 2022)
    </p>
    <p>
        <a target="_blank"
            href="https://www1.folha.uol.com.br/colunas/papo-de-responsa/2022/11/compromisso-com-a-doacao-para-mudar-o-brasil.shtml">Organizações
            do terceiro setor realizam Dia de Doar</a> (Observatório do Terceiro Setor, 2022)
    </p>
    <p>
        <a target="_blank"
            href="https://www1.folha.uol.com.br/colunas/papo-de-responsa/2022/11/compromisso-com-a-doacao-para-mudar-o-brasil.shtml">Dia
            de Doar mobiliza o país e incentiva a sociedade a ser mais colaborativa</a> (Mundo do Marketing, 2022)
    </p>
    <p>
        <a target="_blank"
            href="https://www1.folha.uol.com.br/colunas/papo-de-responsa/2022/11/compromisso-com-a-doacao-para-mudar-o-brasil.shtml">A
            Generosidade Radical e o Dia de Doar</a> (Stanford Social Innovation Review Brasil, 2022)
    </p>
</section>