import { Component, Input } from '@angular/core';
import { PageItem } from '../layout.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from 'src/app/shared/components/success-dialog/success-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() pages!: PageItem[];

  constructor(private dialog: MatDialog){}

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    mensagem: new FormControl('', [Validators.required]),
  });

  onSubmit(){
    this.dialog.open(SuccessDialogComponent)
  }
}
