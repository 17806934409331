<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Sala de imprensa
        </h1>
        <p>
            Milhões de pessoas todos os anos se reúnem para celebrar a doação, compartilhar boas ações e fortalecer a
            cultura de
            doação no Dia de Doar.
        </p>
        <!-- <a class="round background-dark-blue" mat-button target="_blank"
            href="https://diadedoar.org.br/noticias/dia-de-doar-mobiliza-mais-de-28-milhoes-de-pessoas-com-acoes-comunitarias-em-72-cidades-e-abrangencia-em-todas-as-regioes-do-pais/ ">Confira
            um resumo de 2022</a> -->
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_noticia_imprensa.png" alt="picture">
    </section>
</section>