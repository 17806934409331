<section class="other">
    <section class="title">
        <h2>Outras histórias de doação</h2>
        <a class="round background-dark-blue" mat-button>Ver mais publicações ></a>
    </section>
    <section class="cards" *ngIf="newsService.posts$ | async as posts">
        <ng-container *ngFor="let post of posts; let i = index">
            <mat-card *ngIf="i < 3">
                <mat-card-header *ngIf="post.banner">
                    <img class="card-image" [src]="post.banner" alt="">
                </mat-card-header>
                <mat-card-content>
                    <span class="content">
                        <p>{{timestampToDate(post.data) | date:'fullDate'}}</p>
                        <h3>{{post.titulo}}</h3>
                        <div [innerHTML]="post.conteudo"></div>

                    </span>
                </mat-card-content>
                <mat-card-footer>
                    <a class="round" (click)="openDialog(post)">Ler mais</a>

                </mat-card-footer>
            </mat-card>
        </ng-container>
    </section>
</section>