import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { BaseCollectionService } from '../collections/base-collection.service';
import { result } from '../models/result.model';

@Injectable({
  providedIn: 'root'
})
export class ResultsService extends BaseCollectionService<result>  {

  protected override collectionName: string = 'results';
  protected override limit = 100;

  protected override sortObj = {
    active: "ano",
    direction:"desc"
  }

  private lastresultLoaded:any = null;
  public override resultsCount = 0;

  private _results$ = new BehaviorSubject<result[]>([]);
  public results$ = this._results$.asObservable();

  constructor(protected override fireStore:Firestore) {
    super(fireStore);
    this.getresultsCount();
    this.getresults();
  }

  private async getresultsCount(){
    this.resultsCount = await this.docCount();
  }

  public async createresult(result:result){
    this.createDoc(result).then(()=>this.resetLocal())
  }

  public async editresult(result:result, id:string){
    this.editDoc(result, id).then(()=>this.resetLocal())
  }

  public async getresults(searchString: string | null = null){
    if(searchString){
      this._results$.next([]);
      this.lastresultLoaded = null;
    }
    const docs = (await this.getDocs(this.lastresultLoaded, searchString)).docs;
    if(docs.length > 0){
      this.lastresultLoaded = docs[docs.length-1];
      const resultsArr = docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as result));
      // console.log(resultsArr)
      this._results$.next([...this._results$.value, ...resultsArr]);
    }
  }

  clearSearch(){
    this._results$.next([]);
    this.lastresultLoaded = null;
    this.getresults();
  }

  public async excluderesult(id:string){
    await this.deleteDoc(id).then(()=>this.resetLocal());
  }

  private resetLocal(){
      this.lastresultLoaded = null;
      this._results$.next([]);
      this.getresults();
      this.getresultsCount();
  }
}
