<section class="links">
    <a href="https://calendar.google.com/calendar/render?cid=7e38c82bf4f75aa907e07bc889e58144243750e40b49cb7f23af479915dc11e5@group.calendar.google.com"
        target="_blank">

        <mat-card class="blue mat-elevation-z0">
            <mat-card-content>
                <img src="assets/mascotes/PersonagemCiano.png" alt="picture">
                <h4>
                    Adicione o
                    <span class="text-blue">
                        DDD
                    </span> ao seu calendário
                </h4>

            </mat-card-content>
        </mat-card>
    </a>
    <mat-card class="yellow mat-elevation-z0">
        <mat-card-content>
            <img src="assets/mascotes/personagemAmareloLinks.png" alt="picture">
            <h4>Inscreva-se na nossa
                <span class="text-yellow">
                    newsletter
                </span>
            </h4>
        </mat-card-content>
    </mat-card>
    <a href="https://chat.whatsapp.com/K8HyT0ySeawBC03ouxpqPN" target="_blank">
        <mat-card class="green mat-elevation-z0">
            <mat-card-content>
                <img src="assets/mascotes/personagemVerdeLinks.png" alt="picture">
                <h4>Participe dos nossos grupos de
                    <span class="text-green">
                        WhatsApp
                    </span>
                </h4>

            </mat-card-content>
        </mat-card>
    </a>
</section>