import { Injectable } from '@angular/core';
import { NewsPost } from '../models/posts.model';
import { BaseCollectionService } from '../collections/base-collection.service';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends BaseCollectionService<NewsPost> {

  protected override collectionName: string = 'news';

  protected override sortObj = {
    active: "data",
    direction:"desc"
  }

  protected override limit = 15;

  private lastPostLoaded:any = null;
  public postsCount = 0;

  public filterField: string | null = null;
  public filterValue: string | null = null;

  private _posts$ = new BehaviorSubject<NewsPost[]>([]);
  public posts$ = this._posts$.asObservable();

  constructor(protected override fireStore:Firestore) {
    super(fireStore);
    this.getPostsCount();
    // this.getPosts();
  }

  private async getPostsCount(){
    this.postsCount = await this.docCount();
  }

  public async createPost(post:NewsPost){
    this.createDoc(post).then(()=>this.resetLocal())
  }

  public async editPost(post:NewsPost, id:string){
    this.editDoc(post, id).then(()=>this.resetLocal())
  }

  public async getPosts(searchString: string | null = null){
    if(searchString){
      this._posts$.next([]);
      this.lastPostLoaded = null;
    }
    // console.log(this.filterField, this.filterValue)
    const docs = (await this.getDocs(this.lastPostLoaded, searchString, this.filterField, this.filterValue)).docs;
    if(docs.length > 0){
      this.lastPostLoaded = docs[docs.length-1];
      const resultsArr = docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as NewsPost));
      this._posts$.next([...this._posts$.value, ...resultsArr]);
    }
  }

  clearSearch(){
    this._posts$.next([]);
    this.lastPostLoaded = null;
    this.getPosts();
  }

  public async excludePost(id:string){
    await this.deleteDoc(id).then(()=>this.resetLocal());
  }

  public resetLocal(){
      this.lastPostLoaded = null;
      this._posts$.next([]);
      this.getPosts();
      this.getPostsCount();
  }
}
