<section class="presentation">
    <section class="left-container">
        <p>A mídia é uma das nossas principais ferramentas para conscientizar sobre a importância que as doações têm
            para o
            desenvolvimento institucional do terceiro setor. Por isso, adoramos trabalhar com jornalistas, editores e
            transformadores criativos para contar histórias incríveis sobre o poder da generosidade radical.
        </p>
        <p>
            Através das ações do Dia de Doar, podemos continuar defendendo as causas em que acreditamos e construindo
            comunidades
            mais justas. Ao longo do ano, vimos esforços comunitários extraordinários bem-sucedidos para ajudar os
            trabalhadores da
            linha de frente, alcançar os afetados desproporcionalmente pela pandemia e pela crise econômica, unir apoio
            e ativismo
            pela injustiça racial e desigualdade sistêmica, mobilizar a participação cívica e continuar a impulsionar
            doações e
            ações para causas e comunidades em todo o mundo.
        </p>
        <p>Entre em contato conosco para agendar uma entrevista, participação em eventos, solicitação de palestrante ou
            para saber mais sobre o maior omvimento de generosidade do mundo.</p>
        <a class="round background-light-blue" mat-button
            href="https://wa.me/5511991752484?text=Ol%C3%A1%21+Sou+da+imprensa+e+estou+interessado%28a%29+em+falar+sobre+o+Dia+de+Doar"
            target="_blank"> Entre em contato conosco</a>
    </section>
    <section class="right-container">
        <img src="assets/mascotes/homeapresentacao.png" alt="">
    </section>
</section>