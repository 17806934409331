import { Injectable, OnInit } from '@angular/core';
import { BaseCollectionService } from '../collections/base-collection.service';
import { NewsPost } from '../models/posts.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Firestore } from '@angular/fire/firestore';

enum NewsCategory {
  'home',
  'sobre',
  'imprensa',
  'resultados',
  'faq',
  'campanhas',
  'empresas',
  'embaixadores',
  'inscreva-se',
  'inspire-se',
  'dados',
  'pessoas-e-cia',
  'gestao-publica',
  'ongs',
}

// Destaques
// Institucional
// ONGs
// Artigos
// Empresas
// Campanhas Comunitárias
// Gestão pública
// Pessoas, famílias e amigos
// Minha História de Doação
// Eventos
// Dados
export const NewsCategoryLabel:any = {
  [NewsCategory.home]: "Destaques (Home)",
  [NewsCategory.sobre]: "Sobre",
  [NewsCategory.imprensa]: "Imprensa",
  [NewsCategory.resultados]: "Resultados",
  [NewsCategory.faq]: "FAQ",
  [NewsCategory.campanhas]: "Campanhas Comunitárias",
  [NewsCategory.empresas]: "Empresas",
  [NewsCategory.embaixadores]: "Embaixadores",
  [NewsCategory['inscreva-se']]: "Institucional",
  [NewsCategory['inspire-se']]: "Minha História de Doação",
  [NewsCategory.dados]: "Dados",
  [NewsCategory['pessoas-e-cia']]: "Pessoas, famílias e amigos",
  [NewsCategory['gestao-publica']]: "Gestão pública",
  [NewsCategory.ongs]: "ONGs",
}

@Injectable({
  providedIn: 'root'
})
export class CategoryNewsService extends BaseCollectionService<NewsPost> implements OnInit  {

  protected override collectionName: string = 'news';

  protected override sortObj = {
    active: "data",
    direction:"desc"
  }

  protected override limit = 15;

  private lastPostLoaded:any = null;
  public postsCount = 0;

  public filterField: string = "categorias";
  public filterValue: string | null = "Principais";

  private _posts$ = new BehaviorSubject<NewsPost[]>([]);
  public posts$ = this._posts$.asObservable();

  constructor(protected override fireStore:Firestore) {
    super(fireStore);
    this.getPostsCount();
    this.getPosts();
  }

  ngOnInit(): void {
    this.getPosts();
  }


  private async getPostsCount(){
    this.postsCount = await this.docCount();
  }

  public async createPost(post:NewsPost){
    this.createDoc(post).then(()=>this.resetLocal())
  }

  public async editPost(post:NewsPost, id:string){
    this.editDoc(post, id).then(()=>this.resetLocal())
  }

  public async getPosts(searchString: string | null = null){
    if(searchString){
      this._posts$.next([]);
      this.lastPostLoaded = null;
    }
    // console.log(this.filterField, this.filterValue)
    const docs = (await this.getDocs(this.lastPostLoaded, searchString, this.filterField, this.filterValue)).docs;
    this._posts$.next(docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as NewsPost)));
    // if(docs.length > 0){
    //   this.lastPostLoaded = docs[docs.length-1];
    //   const resultsArr = docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as NewsPost));
    //   // console.log(resultsArr)
    // }
  }

  clearSearch(){
    this._posts$.next([]);
    this.lastPostLoaded = null;
    this.getPosts();
  }

  public async excludePost(id:string){
    await this.deleteDoc(id).then(()=>this.resetLocal());
  }

  public resetLocal(){
      this.lastPostLoaded = null;
      this._posts$.next([]);
      this.getPosts();
      this.getPostsCount();
  }

}
