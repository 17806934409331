<section class="common-questions">
    <section class="header">
        <h2>Dúvidas mais comuns</h2>

        <button class="round background-dark-blue" mat-button [routerLink]="'/faq'">Confira todas as dúvidas</button>
    </section>
    <section class="questions" *ngIf="faqService.faqs$ | async as faqs">
        <mat-expansion-panel *ngFor="let faq of faqs | slice:0:5">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>
                        {{faq.pergunta}}
                    </h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{faq.resposta}}</p>
        </mat-expansion-panel>
    </section>


</section>