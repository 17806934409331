import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { AboutComponent } from './about/about.component';
import { CompaniesComponent } from './companies/companies.component';
import { AmbassadorsComponent } from './ambassadors/ambassadors.component';
import { PressComponent } from './press/press.component';
import { ResultsComponent } from './results/results.component';
import { FaqComponent } from './faq/faq.component';
import { CampaignComponent } from './campaign/campaign.component';
import { InspireComponent } from './inspire/inspire.component';
import { BlogComponent } from './blog/blog.component';
import { DataComponent } from './data/data.component';
import { PeopleAndCoComponent } from './people-and-co/people-and-co.component';
import { OngsComponent } from './ongs/ongs.component';
import { PublicComponent } from './public/public.component';
import { SingleNewsComponent } from './shared/components/single-news/single-news.component';
import { NewsDeatilComponent } from './shared/components/news-deatil/news-deatil.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path:'sobre',
    component:AboutComponent
  },
  {
    path:'imprensa',
    component:PressComponent
  },
  {
    path:'resultados',
    component:ResultsComponent
  },
  {
    path:'faq',
    component:FaqComponent
  },
  {
    path:'campanhas',
    component:CampaignComponent
  },
  {
    path:'empresas',
    component:CompaniesComponent
  },
  {
    path:'embaixadores',
    component:AmbassadorsComponent
  },
  {
    path:'inscreva-se',
    component:SubscribeComponent
  },
  {
    path:'inspire-se',
    component:InspireComponent
  },
  {
    path:'blog',
    component: BlogComponent
  },
  {
    path:'dados',
    component: DataComponent
  },
  {
    path:'pessoas-e-cia',
    component: PeopleAndCoComponent
  },
  {
    path:'gestao-publica',
    component: PublicComponent
  },
  {
    path:'ongs',
    component: OngsComponent
  },
  {
    path: 'noticia/:id',
    component: NewsDeatilComponent
  },
  {
    path:'**',
    redirectTo:'home',
    pathMatch:'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
