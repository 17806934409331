<section class="welcome">
    <section class="left-container">
        <form [formGroup]="partnerForm">
            <h2>Registre sua campanha e faça parte da nossa comunidade!</h2>
            <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Nome" formControlName="nome" required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="E-mail" formControlName="email" required>
            </mat-form-field>
            <!-- <mat-form-field>
                <mat-label>CNPJ</mat-label>
                <input matInput placeholder="CNPJ" formControlName="cnpj" [mask]="'00.000.000/0000-00'" required>
            </mat-form-field> -->
            <mat-form-field>
                <mat-label>Telefone</mat-label>
                <input matInput placeholder="Telefone" formControlName="telefone"
                    [mask]="'(00)0000-0000||(00)00000-0000'" required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Eu sou uma</mat-label>
                <mat-select matNativeControl required formControlName="categoria">
                    <mat-option [value]="Categoria.NON_PROFIT">{{LabelCategoria[Categoria.NON_PROFIT]}}</mat-option>
                    <mat-option
                        [value]="Categoria.CAMPANHA_COMUNITARIA">{{LabelCategoria[Categoria.CAMPANHA_COMUNITARIA]}}</mat-option>
                    <mat-option [value]="Categoria.EMPRESA">{{LabelCategoria[Categoria.EMPRESA]}}</mat-option>
                    <mat-option
                        [value]="Categoria.PESSOA_FISICA">{{LabelCategoria[Categoria.PESSOA_FISICA]}}</mat-option>
                    <mat-option [value]="Categoria.ESCOLA">{{LabelCategoria[Categoria.ESCOLA]}}</mat-option>
                    <mat-option
                        [value]="Categoria.ORGAO_PUBLICO">{{LabelCategoria[Categoria.ORGAO_PUBLICO]}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Nome da Organização</mat-label>
                <input matInput placeholder="" formControlName="link_doacao">
            </mat-form-field>
            <app-b64-input label="Ícone" [formControl]="url_imagem"></app-b64-input>
            <img *ngIf="url_imagem.value" [src]="url_imagem.value" alt="">
            <form [formGroup]="enderecoForm">
                <mat-form-field>
                    <mat-label>CEP</mat-label>
                    <input matInput placeholder="CEP" formControlName="cep" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Rua</mat-label>
                    <input matInput placeholder="Rua" formControlName="rua" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Cidade</mat-label>
                    <input matInput placeholder="Cidade" formControlName="cidade" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Bairro</mat-label>
                    <input matInput placeholder="Bairro" formControlName="bairro" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Estadp</mat-label>
                    <input matInput placeholder="Estado" formControlName="estado" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Número</mat-label>
                    <input matInput placeholder="Número" formControlName="numero">
                </mat-form-field>
            </form>
            <mat-form-field>
                <mat-label>O que você está planejando para a sua campanha?</mat-label>
                <textarea matInput placeholder="Descreva pra gente sua campanha do Dia de Doar"
                    formControlName="sobre"></textarea>
            </mat-form-field>
            <button [disabled]="partnerForm.invalid" mat-button class="round background-light-blue"
                (click)="submit()">Enviar</button>
        </form>
    </section>
    <section class="right-container">
        <h2><strong>Bem vindo(a)!</strong></h2>
        <p>Estamos muito felizes por você se juntar à comunidade do Dia de Doar!</p>
        <p>Não é necessário se inscrever para participar do Dia de Doar. O preenchimento deste formulário te permite
            estar em
            contato conosco para conhecer novidades, compartilhar seu case, configurar sua presença no Mapa da
            Generosidade e
            acessar aos materiais de campanha.</p>
        <img src="assets/mascotes/personagemCeleste.png" alt="">
        <mat-card class="mat-elevation-z0">
            <mat-card-content>
                <h3>Qual é a diferença entre Campanha Comunitária e as demais campanhas?</h3>
                <p>Quem participa do Dia de Doar são organizações sociais, empresas, escolas, gestores públicos, pessoas
                    e outros tipos de
                    organizações comprometidas com a generosidade e a criação de mudanças positivas. </p>
                <p>
                    As Campanhas Comunitárias do Dia de Doar são coalizões que se reúnem em torno de uma geografia comum
                    (por exemplo,
                    cidade, vila ou região) ou uma causa comum (por exemplo, Dia de Doar Kids ou Dia de Doar Óvulos)
                    para trazer o Dia de
                    Doar para suas comunidades e fazer a diferença.
                </p>
                <button mat-button class="round" [routerLink]="['/campanhas']">Saiba mais</button>
            </mat-card-content>
        </mat-card>
    </section>

</section>