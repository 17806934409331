import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseCollectionService } from '../collections/base-collection.service';
import { Partner, Endereco } from '../models/partner.model';
import { GeolocationResponse } from '../models/geolocation.model';

interface viaCepModel{
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}
const codec = new HttpUrlEncodingCodec();

@Injectable({
  providedIn: 'root'
})
export class PartnersService extends BaseCollectionService<Partner>{

  private apiKey = 'AIzaSyAV5ZxsqC1kusNs7lnpcfkgBq1ITp0wpto';

  override skipCache = true;

  protected override collectionName: string = 'parceiros';

  protected override limit: number = 100;

  private lastPartnerLoaded:any = null;
  public partnerCount = 0;

  private _partners$ = new BehaviorSubject<Partner[]>([]);
  public partner$ = this._partners$.asObservable();


  constructor(private httpClient: HttpClient, protected override fireStore: Firestore) {
    super(fireStore);
    this.getPartnersCount();
    // this.sortObj.active, this.sortObj.direction as OrderByDirection
    this.getPartners();
  }

  private async getPartnersCount(){
    this.partnerCount = await this.docCount();
  }

  public getAddress(cep: number):Observable<viaCepModel>{
    const url = `https://viacep.com.br/ws/${cep}/json`;
    return this.httpClient.get<viaCepModel>(url);
  }

  public getGeoLocation(endereco: Endereco):Observable<any>{
    // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY
    // Av. Elisa Rosa Cola Padoan, 333-343 - Fraron, Pato Branco - PR, Brazil
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${codec.encodeKey(endereco.rua)},${codec.encodeKey(String(endereco.numero))},${codec.encodeKey(endereco.bairro)},${codec.encodeKey(endereco.cidade)},${codec.encodeKey(endereco.estado)}&key=${this.apiKey}`;
    return this.httpClient.get<GeolocationResponse>(url).pipe(
      map((res)=>{
        return res.results[0].geometry.location;
      })
    );
  };

  public async createPartner(partner:Partner){
    window.sessionStorage.removeItem('parceiros');
    this.createDoc(partner).then(()=>this.resetLocal())
  }

  public async editPartner(partner:Partner, id:string){
    this.editDoc(partner, id).then(()=>this.resetLocal())
  }

  public async getPartners(searchString: string | null = null){
    if(searchString){
      this._partners$.next([]);
      this.lastPartnerLoaded = null;
    }
    const docs = (await this.getDocs(this.lastPartnerLoaded || 0, searchString)).docs;
    if(docs.length > 0){
      this.lastPartnerLoaded = docs[docs.length-1];
      const resultsArr = docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as Partner));
      console.log(resultsArr)
      this._partners$.next([...this._partners$.value, ...resultsArr]);
    }
  }

  clearSearch(){
    this._partners$.next([]);
    this.lastPartnerLoaded = null;
    this.getPartners();
  }

  public async excludePartner(id:string){
    await this.deleteDoc(id).then(()=>this.resetLocal());
  }

  private resetLocal(){
      this.lastPartnerLoaded = null;
      this._partners$.next([]);
      this.getPartners();
      this.getPartnersCount();
  }

  public override setSortObj(sortObj: any){
    this.lastPartnerLoaded = null;
    this.sortObj = sortObj;
    this._partners$.next([]);
    this.getPartners();
    this.getPartnersCount();
  }
}
