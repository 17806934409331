<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Faça a
            <span class="blue">diferença</span>
            promovendo o Dia de Doar!
        </h1>
        <p>Existem muitas maneiras de usar seu poder para impulsionar a generosidade e fazer a diferença em sua
            comunidade no dia
            <span class="date">{{date | date:'d \'de\' MMMM, y'}}</span>, #DiaDeDoar!
        </p>
        <a class="round background-dark-blue" mat-button href="https://youtube.com/live/llPZApR0ii4"
            target="_blank">Assista o vídeo</a>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_gestao_publica.png" alt="picture">
    </section>
</section>