import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-leader',
  templateUrl: './leader.component.html',
  styleUrls: ['./leader.component.scss']
})
export class LeaderComponent {
  static initialized = false;
  static _pre_fix_should_alert = false;

  ngOnInit() {
    if (LeaderComponent.initialized) return;
    LeaderComponent.initialized = true;

    const URL_RD_STATION = 'https://event-api.rdstation.com.br/v2/form_integrations';
    const openedXHRs: WeakMap<XMLHttpRequest, URL> = new Map();
    const originalOpenFn = XMLHttpRequest.prototype.open;
    const originalSendFn = XMLHttpRequest.prototype.send;

    XMLHttpRequest.prototype.open = function(...args: any) {
      const url = new URL(args[1]);
      openedXHRs.set(this, url);
      return originalOpenFn.apply(this, args);
    };
    XMLHttpRequest.prototype.send = function(...args: any) {
      const url = openedXHRs.get(this);
      if (url?.href !== URL_RD_STATION) return;

      const body = args[0];
      let formData = {};

      try {
        formData = body.form_data || JSON.parse(body).form_data;
      } catch (_error) {}

      if (!LeaderComponent._pre_fix_should_alert) {
        LeaderComponent._pre_fix_should_alert = true;
        return;
      }

      if (
        Object.keys(formData).length === 3 &&
        'email' in formData &&
        'conversion_domain' in formData &&
        'conversion_url' in formData
      ) {
        this.addEventListener('error', () => {
          alert('Ocorreu um erro ao tentar te cadastrar em nossa newsletter, por favor tente novamente');
        }, { once: true });
      }

      return originalSendFn.apply(this, args);
    };
  }

  formGroup = new FormGroup({
    email: new FormControl('')
  });

  onSubmit() {
    alert('Agradecemos por tê-lo(a) conosco nesta jornada.');
  }
}
