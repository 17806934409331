<section class="officialized">
    <section class="left-container">
        <h2>Muitas cidades já oficializaram o Dia de Doar, por meio de lei, como uma data local a ser celebrada</h2>
        <p>A oficialização, sozinha, não tem significado prático - a não ser entrar no calendário local de comemorações.
            Mas ela é
            importante para dar credibilidade ao movimento, e também inspirar outros atores a se engajarem na
            iniciativa, além de
            proporcionar também envolver a administração pública nela.</p>
        <p>Se você pensa em estimular a Câmara Municipal (ou mesmo a Assembleia Legislativa do seu Estado) a levar
            adiante o
            reconhecimento do Dia de Doar, preparamos um modelo de Projeto de Lei para ser utilizado com o representante
            no
            legislativo que você tenha contato.</p>
    </section>
    <section class="right-container">
        <!-- {{apiLoaded}} -->
        <div *ngIf="apiLoaded | async as loaded" class="map-wrapper">
            <!-- google-maps-demo.component.html -->
            <google-map width="100%" [center]="center" [zoom]="zoom" (mapClick)="moveMap($event)"
                (mapMousemove)="move($event)">

                <ng-container *ngIf="cities">
                    <map-marker #marker="mapMarker" *ngFor="let city of cities"
                        [position]="{lat:city.lat, lng:city.lng}" [options]="markerOptions" [clickable]="true" />
                    <map-info-window #info>
                        <ng-container *ngIf="infoContent">
                            <div>{{infoContent.nome}}</div>
                            <!-- <span [innerHTML]="infoContent.sobre"></span> -->
                        </ng-container>
                    </map-info-window>
                </ng-container>
            </google-map>

            <!-- <div>Latitude: {{display?.lat}}</div>
            <div>Longitude: {{display?.lng}}</div> -->
        </div>
    </section>
</section>
<section class="city-list">
    <mat-grid-list cols="3" rowHeight="4:1">
        <ng-container *ngFor="let city of cities">
            <mat-grid-tile>
                <a [href]="city.url" target="_blank">
                    {{city.movimento || city.nome}}
                </a>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
</section>