import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, take, tap } from 'rxjs';
import { SuccessDialogComponent } from 'src/app/shared/components/success-dialog/success-dialog.component';
import { PartnerForm, Categoria, LabelCategoria, Partner } from 'src/app/shared/models/partner.model';
import { PartnersService } from 'src/app/shared/services/partners.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent{
  public Categoria = Categoria;
  public Object = Object;
  public LabelCategoria = LabelCategoria;
  private urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  constructor(private partnerService: PartnersService, private dialog: MatDialog) {
    this.cep.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
    ).subscribe(cep=>{
      if(this.cep.valid) this.partnerService.getAddress(cep).subscribe(res=>{
        this.enderecoForm.get('rua').setValue(res.logradouro);
        this.enderecoForm.get('bairro').setValue(res.bairro);
        this.enderecoForm.get('cidade').setValue(res.localidade);
        this.enderecoForm.get('estado').setValue(res.uf);
      });
    });
  }

  public partnerForm = new FormGroup<any>({
    nome: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required]),
    // cnpj: new FormControl('', [Validators.required]),
    telefone: new FormControl('', [Validators.required]),
    //alterar para link e nome
    link_doacao: new FormControl(''),

    categoria: new FormControl<Categoria>(Categoria.NON_PROFIT, [Validators.required]),
    url_imagem: new FormControl<string>('', [Validators.required]),
    sobre: new FormControl('', [Validators.required]),
    endereco: new FormGroup({
      cep: new FormControl<number | string>('', [Validators.required]),
      rua: new FormControl<string>({value:'', disabled:true}, [Validators.required]),
      numero: new FormControl<string>(''),
      complemento: new FormControl<string>(''),
      bairro: new FormControl<string>({value:'', disabled:true}, [Validators.required]),
      cidade: new FormControl<string>({value:'', disabled:true}, [Validators.required]),
      estado: new FormControl<string>({value:'', disabled:true}, [Validators.required]),
      latitude: new FormControl<string>(''),
      longitude: new FormControl<string>(''),
    })
  });

  get url_imagem(){
    return this.partnerForm.get('url_imagem') as FormControl;
  }

  get enderecoForm(){
    return this.partnerForm.get('endereco') as any;
  }

  get cep(){
    return this.enderecoForm.get('cep') as FormControl;
  }
  get latitude(){
    return this.enderecoForm.get('latitude') as FormControl;
  }
  get longitude(){
    return this.enderecoForm.get('longitude') as FormControl;
  }

  submit(){
    this.partnerService.getGeoLocation(this.enderecoForm.getRawValue())
    .pipe(
      take(1),
      tap(geolocal=>{
        this.latitude.setValue(geolocal.lat);
        this.longitude.setValue(geolocal.lng);
      }),
      tap(()=>{
        const partner:Partner = this.partnerForm.getRawValue() as Partner;
        this.partnerService.createPartner(partner).then(()=>{
          this.dialog.open(SuccessDialogComponent);
        })
      })
    )
    .subscribe();
  }
}
