<!-- <p>campaigns works!</p> -->
<section class="campaigns">
    <section class="title">
        <h1><span>Encontre sua cidade</span> e faça parte da rede de campanhas comunitárias do Dia de Doar</h1>
        <a class="round background-light-yellow" mat-button [routerLink]="'/campanhas'">Saiba mais</a>
    </section>

    <section class="carousel" *ngIf="slides">
        <app-swiper [slides]="slides"></app-swiper>
    </section>
    <section class="cards">
        <mat-card>
            <mat-card-content>
                <img src="assets/photos/home_doarondonia.png" alt="">
            </mat-card-content>
            <mat-card-footer>
                #DoaRondônia
            </mat-card-footer>
        </mat-card><mat-card>
            <mat-card-content>
                <img src="assets/photos/home_doasalvador.png" alt="">
            </mat-card-content>
            <mat-card-footer>#DoaSalvador
            </mat-card-footer>
        </mat-card><mat-card>
            <mat-card-content>
                <img src="assets/photos/home_doaribeirao.png" alt="">
            </mat-card-content>
            <mat-card-footer>
                #DoaRibeirãoPreto
            </mat-card-footer>
        </mat-card>
    </section>
</section>