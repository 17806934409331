import { memoryLocalCache, persistentLocalCache, persistentMultipleTabManager } from "@angular/fire/firestore";

export const environment = {
  firebase: {
    apiKey: "AIzaSyBh2ma8nTVf0JxPfmMfhTU7XkbOazvPKNA",
  authDomain: "app-dia-de-doar.firebaseapp.com",
  projectId: "app-dia-de-doar",
  storageBucket: "app-dia-de-doar.appspot.com",
  messagingSenderId: "24398523171",
  appId: "1:24398523171:web:d0b3142a51480c28c4fa96",
    localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()}),
  },
};