import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { Observable, map, tap, catchError, of } from 'rxjs';
import { Partner } from '../../models/partner.model';
import { PartnersService } from '../../services/partners.service';
import { DashboardService } from '../../services/dashboard.service';
import { CidadeOficializada } from '../../models/dashboard.model';

@Component({
  selector: 'app-officialized-cities',
  templateUrl: './officialized-cities.component.html',
  styleUrls: ['./officialized-cities.component.scss']
})
export class OfficializedCitiesComponent {
  infoContent!:Partner;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  partners!: Partner[];
  cities!: CidadeOficializada[];

  icon:google.maps.Icon = {
    url: "assets/icons/google_maps_pin.png", // url
    scaledSize: {height:80, width:80} as google.maps.Size, // scaled size
    // origin: {x:0, y:0} as google.maps.Point, // origin
    // anchor: {x:0, y:0} as google.maps.Point// anchor
};



// equals(other: google.maps.Size|null): boolean;
//     /**
//      * The height along the y-axis, in pixels.
//      */
//     height: number;
//     /**
//      * Returns a string representation of this Size.
//      */
//     toString(): string;
//     /**
//      * The width along the x-axis, in pixels.
//      */
//     width: number;

  markerOptions: google.maps.MarkerOptions = {draggable: false, icon:this.icon};
  apiLoaded: Observable<boolean>;
  openInfo(marker: any, content:any) {
      this.infoContent = content
      this.info.open(marker)
  }

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 5;
  display!: google.maps.LatLngLiteral;



  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng!.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng!.toJSON();
  }

  constructor(httpClient: HttpClient, public partnersService:PartnersService, public dashboardService:DashboardService) {
    // If you're using the `<map-heatmap-layer>` directive, you also have to include the `visualization` library
    // when loading the Google Maps API. To do so, you can add `&libraries=visualization` to the script URL:
    // https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=visualization

    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAV5ZxsqC1kusNs7lnpcfkgBq1ITp0wpto', 'callback')
        .pipe(
          map(() => true),
          tap(()=>{
            // this.partnersService.partner$.subscribe(res=>{
            //   this.partners = res
            // });

            this.dashboardService.dashboard.subscribe(res=>{
              // console.log(res)
              this.cities = res.cidades;
              // console.log(this.cities)
            })
          }),
          catchError(() => of(false)),
        );

    navigator.geolocation.getCurrentPosition(res=>{
      this.center = {lat: res.coords.latitude, lng: res.coords.longitude}
    })



  }


}
