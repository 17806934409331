import { SlicePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection, getCountFromServer, getDocs, getFirestore, doc, updateDoc, DocumentData, QueryDocumentSnapshot, deleteDoc, orderBy, query, limit, startAfter, OrderByDirection, QuerySnapshot, startAt, endAt, and, where, getDoc, getDocsFromCache } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseCollectionService<T = Object> {

  protected abstract collectionName: string;

  public skipCache = false;

  constructor(protected fireStore:Firestore) { }

  protected sortObj = {
    active: "nome",
    direction:"asc"
  }

  protected resultsCount: number | null = 0;

  protected limit: number = 5;

  get collection() {
    return collection(this.fireStore, this.collectionName);
  }

  async docCount(){
    return this.resultsCount || (await getCountFromServer(this.collection)).data().count;
  }

  async getDocs(lastEntry: T|null = null, queryText: String | null = null, filterField: string | null = null, filterValue: string | null = null, orderByField: string = this.sortObj.active, orderByDirection: OrderByDirection = ( this.sortObj.direction ? this.sortObj.direction : 'desc') as OrderByDirection ):Promise<QuerySnapshot<DocumentData>>{
    this.resultsCount = null;
    // const startAtQ = queryText ? (startAt(queryText), endAt(queryText+"\uf8ff")) : null;
    let q = query(this.collection, orderBy(orderByField, orderByDirection), limit(this.limit));
    if(filterField && filterValue) {
      q = query(q, where(filterField, "array-contains", filterValue))}
    if(queryText) q = query(q, startAt(queryText), endAt(queryText+"\uf8ff"));
    if(lastEntry) q = query(q, startAfter(lastEntry));
    // if(queryText) q = query(q, where(this.sortObj.active, "in", queryText));

    const cacheDate =window.sessionStorage.getItem('cacheDate')!;
    const lastUpdate = window.sessionStorage.getItem('lastUpdate')!
    const cacheDuration = window.sessionStorage.getItem('cacheDuration')

    if(cacheDuration){
      if(Date.parse(cacheDate) - Date.now() > Number(cacheDuration)) window.sessionStorage.removeItem(`${this.collectionName}${filterField || ''}${filterValue || ''}`)
    }
    if(lastUpdate){
      const lastUpdateDate = Date.parse(lastUpdate);
      if(Date.parse(cacheDate) < lastUpdateDate) window.sessionStorage.removeItem(`${this.collectionName}${filterField || ''}${filterValue || ''}`);
    }


    let get:Function
    if(window.sessionStorage.getItem(`${this.collectionName}${filterField || ''}${filterValue || ''}`) === 'true' || this.skipCache ){
      get = getDocs;
      // console.log('get from db', `${this.collectionName}${filterField || ''}${filterValue || ''}`)
      window.sessionStorage.setItem('cacheDate', Date.now().toString());
    } else{
      get = getDocsFromCache;
      // console.log('get from cache', `${this.collectionName}${filterField || ''}${filterValue || ''}`)
    }

    const docs = await get(q);
    this.resultsCount = docs.size;
    window.sessionStorage.setItem(`${this.collectionName}${filterField || ''}${filterValue || ''}`, 'true');
    // docs.metadata.
    docs.docChanges().forEach((change: any)=>{
      // console.log(change)
      if(change.type ==='modified'  ) window.sessionStorage.setItem(`${this.collectionName}${filterField || ''}${filterValue || ''}`, 'false')
    })
    return docs;
  }

  async createDoc(doc: T){
    return await addDoc(this.collection, doc as Object);
  }

  async editDoc(docObj: T, docId: string){
    const d = doc(this.collection, docId);
    await updateDoc(d, Object.assign({}, docObj));
    return docObj;
  }

  async deleteDoc(docId: string){
    const d = doc(this.collection, docId);
    await deleteDoc(d);
  }

  setSortObj(sortObj: any){
    this.sortObj = sortObj;
    this.getDocs();
  }
}
